import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';

export const Thread = () => {
  const routes = [
    {
      name: 'Chats',
      path: '/app/chats',
    },
    {
        name: 'Chat',
        path: '/app/chats/:id',
    }
  ];
  const navigate = useNavigate();
  return (
    <ContentLayout
      title="Chat"
      end={<Button onClick={() => navigate('/app')}>Send Message</Button>}
    >
      <Breadcrumbs routes={routes} />

      <div className="mt-8">
       
      </div>
    </ContentLayout>
  );
};
