import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PlusIcon } from '@heroicons/react/outline';

import { useAddValuationOpinion } from '../api/addValuationOpinion';
import { useDueDeligenceAsset } from 'features/dueDeligence';

export const AddValuationOpinion = ({ id }) => {
  const addValuationOpinionMutation = useAddValuationOpinion();

  const assetQuery = useDueDeligenceAsset({ id });

  if (addValuationOpinionMutation.isLoading) return <FullScreenSpinner />;

  return (
    <>
      {assetQuery.data?.valuation?.valuation_opinion ? (
        `Your valuation opinion is UGX ${assetQuery.data?.valuation?.valuation_opinion?.toLocaleString()}`
      ) : (
        <Authorization allowedRoles={[ROLES.VALUATION]}>
          <FormDrawer
            isDone={addValuationOpinionMutation.isSuccess}
            size="sm"
            triggerButton={
              <Button size="sm" startIcon={<PlusIcon className="h-4 w-4" />}>
                Add Valuation Opinion
              </Button>
            }
            title="Add Valuation Opinion"
            submitButton={
              <Button
                form="valuation-opinion"
                type="submit"
                size="sm"
                isLoading={addValuationOpinionMutation.isLoading}
              >
                Submit
              </Button>
            }
          >
            <Form
              id="valuation-opinion"
              onSubmit={async (values) => {
                await addValuationOpinionMutation.mutateAsync({
                  data: values,
                  id: assetQuery.data._id,
                });
              }}
            >
              {({ register, formState }) => (
                <>
                  <InputField
                    label="Valuation Opinion"
                    type="number"
                    error={formState.errors['valuation_opinion']}
                    registration={register('valuation_opinion')}
                  />
                </>
              )}
            </Form>
          </FormDrawer>
        </Authorization>
      )}
    </>
  );
};
