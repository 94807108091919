import PropTypes from 'prop-types';
import * as React from 'react';
import { UserNavigation } from './components/UserNavigation';
import { useAuth } from 'lib/auth';
import { Bell, MessageCircle, Settings } from 'react-feather';
import { Logo } from './components/Logo';
import { Link } from 'react-router-dom';
import { REACT_APP_STATUS } from 'config';
import { REACT_APP_STATUS_COLOR } from 'config';
import { useNotifications } from 'features/notifications';
import { useUnreadMessageCount } from 'features/messages';

export const MainLayout = ({ children }) => {
  const { user } = useAuth();

  const messagesQuery = useUnreadMessageCount();
  const notificationsQuery = useNotifications();

  const unreadNotifications = notificationsQuery.data?.filter(
    (notification) => notification.read === false
  ).length;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  if (!notificationsQuery.data) return null;

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <div className="bg-[#FAFAFA] flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative bg-white px-4 md:px-24 z-10 items-center flex-shrink-0 flex py-4 shadow">
          <Logo />
          <div className="px-10 text-xs text-gray-700 font-bold truncate overflow-hidden hidden md:block">
            <span className="text-[#50CAF3]">
              {user.basicInformation?.firstName} {user.basicInformation?.lastName}
            </span>{' '}
            currently logged in.
          </div>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex space-x-6 items-center md:ml-6">
              <div className="hidden md:flex flex-row items-center space-x-2">
                <div className={`rounded-full h-4 w-4 bg-${REACT_APP_STATUS_COLOR}-500`}></div>
                <div className="">{REACT_APP_STATUS}</div>
              </div>
              <Link to="/app/chats">
                <div className="relative">
                  <MessageCircle />
                </div>
              </Link>
              <Link to="/app/notifications">
                <div className="relative">
                  <Bell />
                  {unreadNotifications > 0 && (
                    <div className="absolute bg-secondary-100 h-6 w-6 -mt-9 ml-2 rounded-full text-center text-white flex items-center justify-center text-xs">
                      {unreadNotifications}
                    </div>
                  )}
                </div>
              </Link>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          {children}

          <div class="mx-8">
            <div class="px-6 sm:px-10 text-xs text-gray-800 truncate overflow-hidden text-center">
              © {currentYear}, Enabling Finance Limited. or its affiliates. All rights reserved.
            </div>
            <div class="px-6 sm:px-10 text-xs text-gray-800 truncate overflow-hidden text-center">
              <a href="/terms-and-conditions">Terms and Conditions</a> .{' '}
              <a href="/general-policy">General Policy</a> .{' '}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
