import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const uploadTenancyAgreements = ({ data, id }) => {
  return axios.post(`/duedeligences/${id}/tenancy_agreements`, data);
};

export const useUploadTenancyAgreements = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingAsset) => {
      await queryClient.cancelQueries(['due_deligence_asset', updatingAsset?.id]);

      const previousAsset = queryClient.getQueryData[('due_deligence_asset', updatingAsset?.id)];

      queryClient.setQueryData(['due_deligence_asset', updatingAsset?.id], {
        ...previousAsset,
        ...updatingAsset.data,
        id: updatingAsset.id,
      });

      return { previousAsset };
    },
    onError: (_, __, context) => {
      if (context?.previousAsset) {
        queryClient.setQueryData(
          ['due_deligence_asset', context.previousAsset.id],
          context.previousAsset
        );
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['due_deligence_asset', data._id]);
      addNotification({
        type: 'success',
        title: 'Submitted sucessfully',
      });
    },
    ...config,
    mutationFn: uploadTenancyAgreements,
  });
};
