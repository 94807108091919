import { ContentLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';

import { Head } from 'components/Head';
import { useEffect, useState } from 'react';
import { DevelopmentDialog } from '../components/DevelopmentDialog';
import { UpdateAvatarIcon } from 'features/users';
import {
  UploadAppraisalReport,
  UploadBankStatement,
  UploadBoundaryOpeningSurveyReport,
  UploadCertifiedCompanyDocuments,
  UploadCompanySearch,
  UploadCopyOfPurchaseAgreement,
  UploadDirectorGuarantorshipContracts,
  UploadLetterOfIntroductionFromChair,
  UploadMortgageDeed,
  UploadNeighborsMet,
  UploadPassportPhotoNationalIDSpouce,
  UploadPicturesOfLandAndTeamOnground,
  UploadSignedTransferForms,
  UploadSpoucalConsentForm,
  UploadTenancyAgreements,
  UploadValuationReport,
  useMyChecklist,
  useUploadAppraisalReport,
  useUploadBankStatement,
  useUploadBoundaryOpeningSurveyReport,
  useUploadCertifiedCompanyDocuments,
  useUploadCompanySearch,
  useUploadCopyOfPurchaseAgreement,
  useUploadDirectorGuarantorshipContracts,
  useUploadLetterOfIntroductionFromChair,
  useUploadMortgageDeed,
  useUploadNeighborsMet,
  useUploadPassportPhotoNationalIdSpouce,
  useUploadPicturesOfLandAndTeamOnground,
  useUploadSignedTransferForms,
  useUploadSpoucalConsentForm,
  useUploadTenancyAgreements,
  useUploadTitleSearchLetter,
  useUploadValuationReport,
} from 'features/dueDeligence';
import { FullScreenSpinner } from 'components/Elements';
import { AddValuationOpinion } from '../components/AddValuationOpinion';

export const Dashboard = () => {
  const { user } = useAuth();

  const checklistQuery = useMyChecklist();

  const [updateAvatar, setUpdateAvatar] = useState(false);
  const [showDevelopment, setShowDevelopment] = useState(false);

  console.log(checklistQuery.data);

  useEffect(() => {
    const showDevelopment = localStorage.getItem('showDevelopment');

    if (!showDevelopment) {
      const timer = setTimeout(() => {
        setShowDevelopment(true);
      }, 2000);
      return () => clearTimeout(timer);
    }

    // console.log(user.basicInformation?.avatar);

    if (!user.basicInformation?.avatar) {
      setUpdateAvatar(true);
    }
  }, []); // Run once after mounting

  const uploadBoundaryOpeningSurveyReportMutation = useUploadBoundaryOpeningSurveyReport();
  const uploadTitleSearchLetterMutation = useUploadTitleSearchLetter();
  const uploadValuationReportMutation = useUploadValuationReport();
  const uploadAppraisalReportMutation = useUploadAppraisalReport();
  const uploadTenancyAgreementsMutation = useUploadTenancyAgreements();
  const uploadPicturesOfLandAndTeamOnGroundMutation = useUploadPicturesOfLandAndTeamOnground();
  const uploadCopyOfPurchaseAgreementMutation = useUploadCopyOfPurchaseAgreement();
  const uploadBankStatementMutation = useUploadBankStatement();
  const uploadCompanySearchMutation = useUploadCompanySearch();
  const uploadSpoucalConsentFormMutation = useUploadSpoucalConsentForm();
  const uploadSignedTransferFormsMutation = useUploadSignedTransferForms();
  const uploadLetterOfIntroductionFromChairMutation = useUploadLetterOfIntroductionFromChair();
  const uploadMortgageDeedMutation = useUploadMortgageDeed();
  const uploadNeighborsMetMutation = useUploadNeighborsMet();
  const uploadPassportPhotoNationalIdSpouceMutation = useUploadPassportPhotoNationalIdSpouce();
  const uploadDirectorGuarantorshipContractsMutation = useUploadDirectorGuarantorshipContracts();
  const uploadCertifiedCompanyDocumentsMutation = useUploadCertifiedCompanyDocuments();

  if (
    uploadBoundaryOpeningSurveyReportMutation.isLoading ||
    uploadTitleSearchLetterMutation.isLoading ||
    uploadValuationReportMutation.isLoading ||
    uploadAppraisalReportMutation.isLoading ||
    uploadTenancyAgreementsMutation.isLoading ||
    uploadPicturesOfLandAndTeamOnGroundMutation.isLoading ||
    uploadCopyOfPurchaseAgreementMutation.isLoading ||
    uploadBankStatementMutation.isLoading ||
    uploadCompanySearchMutation.isLoading ||
    uploadSpoucalConsentFormMutation.isLoading ||
    uploadSignedTransferFormsMutation.isLoading ||
    uploadLetterOfIntroductionFromChairMutation.isLoading ||
    uploadMortgageDeedMutation.isLoading ||
    uploadNeighborsMetMutation.isLoading ||
    uploadPassportPhotoNationalIdSpouceMutation.isLoading ||
    uploadDirectorGuarantorshipContractsMutation.isLoading ||
    uploadCertifiedCompanyDocumentsMutation.isLoading
  )
    return <FullScreenSpinner />;

  const handleCloseDevelopmentDialog = () => {
    localStorage.setItem('showDevelopment', 'true');
    setShowDevelopment(false);
  };

  console.log(checklistQuery.data?.boundary_opening_survey_report?.document_required);

  return (
    <ContentLayout title={`Hey 👋🏼 ${user.basicInformation?.firstName}`}>
      <Head title="Home" />
      <DevelopmentDialog isOpen={showDevelopment} onClose={handleCloseDevelopmentDialog} />
      <UpdateAvatarIcon requireUpdate={updateAvatar} />
      <div className="">
        {checklistQuery.data?.length > 1 ? (
          <div className="mb-4 font-bold text-lg">Please take action on the following.</div>
        ) : (
          <div className="mb-4 font-md text-sm bg-white p-3 rounded-2xl shadow">
            You currently have no assigned items in due diligence checklist. Thank you for being
            part of Finablr's Service Providers.
          </div>
        )}

        {checklistQuery.data?.map((item) => {
          if (item.boundary_opening_survey_report)
            return <UploadBoundaryOpeningSurveyReport id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.valuation_report)
            return (
              <>
                <div className="flex flex-row items-center justify-end mb-3">
                  <AddValuationOpinion id={checklistQuery.data[0].collateral} />
                </div>
                <UploadValuationReport id={checklistQuery.data[0].collateral} />
              </>
            );
        })}

        {checklistQuery.data?.map((item) => {
          if (item.appraisal_report)
            return <UploadAppraisalReport id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.tenancy_agreements)
            return <UploadTenancyAgreements id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.pictures_of_land_and_team_onground)
            return <UploadPicturesOfLandAndTeamOnground id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.copy_of_purchase_agreement)
            return <UploadCopyOfPurchaseAgreement id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.bank_statement)
            return <UploadBankStatement id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.company_search)
            return <UploadCompanySearch id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.spoucal_consent_form)
            return <UploadSpoucalConsentForm id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.signed_transfer_forms)
            return <UploadSignedTransferForms id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.letter_of_introduction_from_chair)
            return <UploadLetterOfIntroductionFromChair id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.mortgage_deed)
            return <UploadMortgageDeed id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.neighbors_met)
            return <UploadNeighborsMet id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.passport_photo_national_id_spouce)
            return <UploadPassportPhotoNationalIDSpouce id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.director_guarantorship_contracts)
            return <UploadDirectorGuarantorshipContracts id={checklistQuery.data[0].collateral} />;
        })}

        {checklistQuery.data?.map((item) => {
          if (item.certified_company_documents)
            return <UploadCertifiedCompanyDocuments id={checklistQuery.data[0].collateral} />;
        })}
      </div>
    </ContentLayout>
  );
};
