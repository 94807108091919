import React from 'react';
import { Spinner, MDPreview } from 'components/Elements';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { Link, useParams } from 'react-router-dom';
import { DeleteNotification } from '../components/DeleteNotification';

import { useMarkNotificationAsRead } from '../api/markAsRead';
import { useMarkNotificationAsUnread } from '../api/markAsUnread';

import { useNotification } from '../api/getNotification';
import { formatDate } from 'utils/format';

export const Notification = () => {
  const { id } = useParams();
  const notificationQuery = useNotification({ id });

  const markAsReadMutation = useMarkNotificationAsRead();
  const markAsUnreadMutation = useMarkNotificationAsUnread();

  const handleMarkAsRead = () => {
    markAsReadMutation.mutate({ id });
  };

  const handleMarkAsUnread = () => {
    markAsUnreadMutation.mutate({ id });
  };

  const routes = [
    {
      name: 'Notifications',
      path: '/app/notifications',
    },
    {
      name: notificationQuery.data?.message?.substring(0, 20).concat('...'),
      path: `/app/notifications/${id}`,
    },
  ];

  if (notificationQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!notificationQuery.data) return null;

  return (
    <>
      <Head title={notificationQuery.data?.message?.substring(0, 20).concat('...')} />
      <ContentLayout title={notificationQuery.data?.message?.substring(0, 20).concat('...')}>
        <Breadcrumbs routes={routes} />
        <div className="mt-6 flex flex-col space-y-16">
          <div className="bg-white p-6 rounded-lg shadow-md flex flex-row items-start justify-between">
            {notificationQuery.data.route ? (
              <Link to={`/app/${notificationQuery.data.route}`} className="">
                <h3 className="text-lg font-semibold">{notificationQuery.data?.message}</h3>
                <p className="text-gray-500 mt-2">{notificationQuery.data?.createdAt}</p>
              </Link>
            ) : (
              <div className="">
                <h3 className="text-lg font-semibold">{notificationQuery.data?.message}</h3>
                <p className="text-gray-500 mt-2">
                  {formatDate(notificationQuery.data?.createdAt)}
                </p>
              </div>
            )}
            <div className="mt-4 flex space-x-4">
              {notificationQuery.data?.read ? (
                <button
                  onClick={handleMarkAsUnread}
                  className="btn bg-gray-500 rounded-full p-1 px-2 text-white"
                >
                  Unread
                </button>
              ) : (
                <button
                  onClick={handleMarkAsRead}
                  className="btn bg-primary-100 rounded-full p-1 px-2 text-white"
                >
                  Read
                </button>
              )}
              <DeleteNotification id={id} />
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
