import { Spinner } from 'components/Elements';
import React from 'react';
import { useUser } from '../api/getUser';

export const AssignedTo = ({ id }) => {
  const userQuery = useUser({ id });

  if (userQuery.isLoading) {
    return (
      <div className="">
        <Spinner size="sm" />
      </div>
    );
  }
  return (
    <div className="flex flex-row items-cente space-x-2">
      <div className="rounded-full w-12 h-12 items-center justify-center text-center p-4 bg-red-100">
        {userQuery.data?.basicInformation?.firstName?.charAt(0)}
      </div>
      <div className="text-sm">
        <div className="">{userQuery.data?.authentication?.username}</div>
        <div className="text-gray-500">
          {userQuery.data?.basicInformation?.contactInformation?.email}
        </div>
      </div>
    </div>
  );
};
