import React, { useState } from 'react';
import { useUploadNeighborsMet } from '../api/uploadNeighborsMet';
import { Button, FullScreenSpinner, Spinner } from 'components/Elements';
import { Form } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { AssignedTo } from './AssignedTo';
import { UPLOADS_URL } from 'config';
import { Check } from 'react-feather';
import { useDueDeligenceAsset } from '../api/getDueDeligenceAsset';

export const UploadNeighborsMet = ({ id }) => {
  const [neighbors_met, setNeighborsMet] = useState(null);

  const uploadNeighborsMetMutation = useUploadNeighborsMet();

  const assetQuery = useDueDeligenceAsset({ id });

  const [neighbors, setNeighbors] = useState([{ name: '', phoneNumber: '' }]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleAddNeighbor = () => {
    setNeighbors([...neighbors, { name: '', phoneNumber: '' }]);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const list = [...neighbors];
    list[index][name] = value;
    setNeighbors(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const neighborsData = neighbors.map((neighbor) => ({
      name: neighbor.name,
      phoneNumber: neighbor.phoneNumber,
    }));
    await uploadNeighborsMetMutation.mutateAsync({
      data: neighborsData,
      id: assetQuery.data._id,
    });
    setIsLoading(false);
    navigate(`/app/due-deligences/${id}/process/success`);
  };

  if (assetQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (uploadNeighborsMetMutation.isLoading) return <FullScreenSpinner />;

  return (
    <div>
      {/* Neighbors Met */}
      {assetQuery.data?.neighbors_met?.document_required &&
        !assetQuery.data?.neighbors_met?.location && (
          <>
            <Form
              id="upload-neighbors-met"
              onSubmit={async () => {
                await uploadNeighborsMetMutation.mutateAsync({
                  data: {
                    neighbors,
                  },
                  id: assetQuery.data._id,
                });
                navigate(`/app/due-deligences/${id}/process/success`);
              }}
            >
              {({ register, formState }) => (
                <>
                  <div>
                    <div className="bg-white rounded-2xl shadow-md p-12 mb-4">
                      <div className="text-lg mb-2">Neighbors Met</div>
                      <div className="text-sm mb-3">
                        Lorem ipsum dolor sit amet consectetur. Diam sit rutrum enim dui lectus
                        pharetra consectetur donec gravida. Faucibus quisque dignissim faucibus
                        commodo odio tellus. Proin euismod non eget.
                      </div>
                      <div className="flex flex-col items-center justify-between">
                        {neighbors.map((neighbor, index) => (
                          <div className="">
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={neighbor.name}
                              onChange={(e) => handleInputChange(index, e)}
                              className="border rounded-lg p-2 mb-2"
                            />
                            <input
                              type="text"
                              name="phoneNumber"
                              placeholder="Phone Number"
                              value={neighbor.phoneNumber}
                              onChange={(e) => handleInputChange(index, e)}
                              className="border rounded-lg p-2"
                            />
                          </div>
                        ))}
                        <div className="">
                          <div className="">Assigned to</div>
                          <AssignedTo id={assetQuery.data?.neighbors_met?.assigned_to} />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={handleAddNeighbor}
                        type="button"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Add Neighbor
                      </button>
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={handleSubmit}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          </>
        )}

      {assetQuery.data?.neighbors_met?.location && (
        <div className="bg-white rounded-2xl shadow-md p-8 grid grid-cols-1 md:grid-cols-3 mb-4">
          <div className="">
            <div className="">Neighbors Met</div>
            <a
              href={`${UPLOADS_URL}/documents/due_deligence/${assetQuery.data?.neighbors_met?.location}`}
              target="_blank"
              rel="noreferrer"
              className="text-primary-100"
            >
              {assetQuery.data?.neighbors_met?.location}
            </a>
          </div>
          <div className="">
            <AssignedTo id={assetQuery.data?.neighbors_met?.assigned_to} />
          </div>
          <div className="flex justify-end">
            <div className="rounded-full bg-green-400 p-3 h-12 w-12 flex items-center justify-center">
              <Check size={20} className="text-white" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
