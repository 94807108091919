import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const markAsRead = ({ data, threadId }) => {
  return axios.post(`/messages/mark-last-message-read/${threadId}`, data);
};

export const useMarkAsRead = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (markAsReadData) => {
        
    },
    onError: (_, __, context) => {
    
    },
    onSuccess: () => {
      queryClient.invalidateQueries('threads');
    },
    ...config,
    mutationFn: markAsRead,
  });
};
