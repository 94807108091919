import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, FullScreenSpinner, Spinner } from 'components/Elements';
import { Form } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { AssignedTo } from './AssignedTo';
import { UPLOADS_URL } from 'config';
import { Check } from 'react-feather';
import { useDueDeligenceAsset } from '../api/getDueDeligenceAsset';
import { useUploadPicturesOfLandAndTeamOnground } from '../api/uploadPicturesOfLandAndTeamOnground';

export const UploadPicturesOfLandAndTeamOnground = () => {
  const [images, setImages] = useState([]);

  const uploadPicturesOfLandAndTeamOnGroundMutation = useUploadPicturesOfLandAndTeamOnground();

  const { id } = useParams();

  const assetQuery = useDueDeligenceAsset({ id });

  const navigate = useNavigate();

  if (assetQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  const handlePicturesOfLandAndTeamOngroundChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newImages = [...images];
        newImages[index] = reader.result;
        setImages(newImages);
      };
      reader.readAsDataURL(file);
    }
  };

  if (uploadPicturesOfLandAndTeamOnGroundMutation.isLoading) return <FullScreenSpinner />;

  return (
    <div>
      {assetQuery.data?.tenancy_agreements?.location &&
        assetQuery.data?.pictures_of_land_and_team_onground?.document_required &&
        !assetQuery.data?.pictures_of_land_and_team_onground?.location && (
          <>
            <Form
              id="upload-pictures-of-land-and-team-onground"
              onSubmit={async () => {
                const bodyFormData = new FormData();
                bodyFormData.append('pictures', images);
                await uploadPicturesOfLandAndTeamOnGroundMutation.mutateAsync({
                  data: bodyFormData,
                  id: assetQuery.data._id,
                });
                navigate(`/app/due-deligences/${id}/process/success`);
              }}
            >
              {({ register, formState }) => (
                <>
                  <div className="bg-white rounded-2xl shadow-md p-12 mb-4">
                    <div className="text-lg mb-2">Pictures of Land and Team on Ground</div>
                    <div className="text-sm mb-3">
                      Lorem ipsum dolor sit amet consectetur. Diam sit rutrum enim dui lectus
                      pharetra consectetur donec gravida. Faucibus quisque dignissim faucibus
                      commodo odio tellus. Proin euismod non eget.
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="relative">
                        <input
                          type="file"
                          id={`image-input`}
                          accept="image/*"
                          className="hidden"
                          onChange={(event) =>
                            handlePicturesOfLandAndTeamOngroundChange(index, event)
                          }
                        />
                        <label
                          htmlFor={`image-input`}
                          className="cursor-pointer bg-white border border-gray-300 rounded-md p-2"
                        >
                          <div className="flex items-center justify-center">
                            <div className="text-primary-100">Upload Pictures</div>
                          </div>
                        </label>
                      </div>
                      <div className="">
                        <div className="">Assigned to</div>
                        <AssignedTo
                          id={assetQuery.data?.pictures_of_land_and_team_onground?.assigned_to}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button
                      isLoading={uploadPicturesOfLandAndTeamOnGroundMutation.isLoading}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </>
        )}

      {assetQuery.data?.pictures_of_land_and_team_onground?.location && (
        <div className="bg-white rounded-2xl shadow-md p-8 grid grid-cols-1 md:grid-cols-3 mb-4">
          <div className="">
            <div className="">Pictures of Land and Team on Ground</div>
            <div className="grid grid-cols-3 gap-6">
              {assetQuery.data?.pictures_of_land_and_team_onground?.pictures?.map(
                (image, index) => {
                  return (
                    <img
                      src={UPLOADS_URL + '/documents/duedeligence/' + image}
                      alt="image"
                      className="rounded-2xl shadow-md"
                    />
                  );
                }
              )}
            </div>
          </div>
          <div className="">
            <AssignedTo id={assetQuery.data?.pictures_of_land_and_team_onground?.assigned_to} />
          </div>
          <div className="flex justify-end">
            <div className="rounded-full bg-green-400 p-3 h-12 w-12 flex items-center justify-center">
              <Check size={20} className="text-white" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
