import { Button } from 'components/Elements';
import { Form, FormDrawer, InputField, PhoneInputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import { PencilIcon } from '@heroicons/react/solid';

import { useUpdateProfile } from '../api/updateProfile';

export const UpdateProfile = () => {
  const { user } = useAuth();
  const updateProfileMutation = useUpdateProfile();

  return (
    <FormDrawer
      isDone={updateProfileMutation.isSuccess}
      size="sm"
      triggerButton={
        <Button startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
          Edit Profile
        </Button>
      }
      title="Edit Profile Details"
      submitButton={
        <Button
          form="update-profile"
          type="submit"
          size="sm"
          isLoading={updateProfileMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form
        id="update-profile"
        onSubmit={async (values) => {
          await updateProfileMutation.mutateAsync({ data: values });
        }}
        options={{
          defaultValues: {
            firstName: user?.basicInformation?.firstName,
            lastName: user?.basicInformation?.lastName,
            email: user?.basicInformation?.contactInformation?.email,
            phoneNumber: user?.basicInformation?.contactInformation?.phoneNumber,
            residentialAddress: user?.addressInformation?.residentialAddress,
            permanentAddress: user?.addressInformation?.permanentAddress,
            
          },
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              label="First Name"
              error={formState.errors['firstName']}
              registration={register('firstName')}
            />
            <InputField
              label="Last Name"
              error={formState.errors['lastName']}
              registration={register('lastName')}
            />

            <PhoneInputField
              label="Phone Number"
              error={formState.errors['phoneNumber']}
              registration={register('phoneNumber')}
            />
            <InputField
              label="Email Address"
              type="email"
              error={formState.errors['email']}
              registration={register('email')}
            />

           

            <InputField
              label="Residential Address"
              error={formState.errors['residentialAddress']}
              registration={register('residentialAddress')}
            />
            <InputField
              label="Permanent Address"
              error={formState.errors['permanentAddress']}
              registration={register('permanentAddress')}
            />
          
          </>
        )}
      </Form>
    </FormDrawer>
  );
};
