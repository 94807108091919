import { ContentLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';

import { UpdateProfile } from '../components/UpdateProfile';
import { UPLOADS_URL } from 'config';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { UpdateAvatar } from '../components/UpdateAvatar';

const Entry = ({ label, value }) => (
  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
  </div>
);

Entry.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export const Profile = () => {
  const { user } = useAuth();

  const routes = [
    {
      name: 'My Account',
      path: '/app/my-account',
    },
  ];
  if (!user) return null;

  return (
    <ContentLayout>
      <div className="text-2xl text-center mb-4 rounded-2xl p-3">User Profile</div>
      <Breadcrumbs routes={routes} />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
        <div className="rounded-2xl mb-4 flex flex-col space-y-2 items-center justify-start">
          <img
            src={UPLOADS_URL + '/images/users/' + user.basicInformation?.avatar}
            className="w-48 h-48"
            alt="avatar"
          />
          <UpdateAvatar id={user._id} />
        </div>
        <div className="md:col-span-3 ">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">User Information</h3>
                <UpdateProfile />
              </div>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details of the user.</p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <Entry label="First Name" value={user.basicInformation?.firstName} />
                <Entry label="Last Name" value={user.basicInformation?.lastName} />
                <Entry
                  label="Phone Number"
                  value={`${user.basicInformation?.contactInformation?.phoneNumber}`}
                />
                <Entry
                  label="Email Address"
                  value={user.basicInformation?.contactInformation?.email}
                />

                <Entry label="Role" value={user.role} />
              </dl>
            </div>
          </div>

          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Address Information</h3>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <Entry
                  label="Residential Address"
                  value={user.addressInformation?.residentialAddress}
                />
                <Entry
                  label="Permanent Address"
                  value={user.addressInformation?.permanentAddress}
                />
              </dl>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
