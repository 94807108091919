import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getDueDeligenceAsset = ({ id }) => {
  return axios.get(`/duedeligences/${id}`);
};

export const useDueDeligenceAsset = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['due_deligence_asset', id],
    queryFn: () => getDueDeligenceAsset({ id }),
  });
};
