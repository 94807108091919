import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getMyChecklist = () => {
  return axios.get('/duedeligences/mychecklist');
};

export const useMyChecklist = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['due_deligence_checklist'],
    queryFn: () => getMyChecklist(),
  });
};
