import { Spinner } from 'components/Elements';
import { MainLayout } from 'components/Layout';
import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const { ThreadsRoutes } = lazyImport(() => import('features/messages'), 'ThreadsRoutes');
const { Dashboard } = lazyImport(() => import('features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('features/users'), 'Profile');
const { NotificationsRoutes } = lazyImport(
  () => import('features/notifications'),
  'NotificationsRoutes'
);
const { DueDeligenceRoutes } = lazyImport(
  () => import('features/dueDeligence'),
  'DueDeligenceRoutes'
);

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/app/my-account', element: <Profile /> },
      { path: '/app/notifications/*', element: <NotificationsRoutes /> },
      { path: '/app/due-deligence/*', element: <DueDeligenceRoutes /> },

      { path: '/app/chats/*', element: <ThreadsRoutes /> },
      { path: '/app/', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
