
import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import MotivationRafiki from 'assets/motivation-rafiki.svg';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();


  const handleStart = () => {
    if (user) {
      navigate('/app');
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <WebLayout>
      <Head title="Service Providers" description="Service Providers" />
      <div className="mb-4 h-screen grid grid-cols-1 md:grid-cols-2 gap-4 mx-4 md:mx-24 items-start">
        <div className="md:ml-24">
          <div className="mt-24 text-4xl md:text-6xl text-secondary-100 font-black">
            <span className="text-primary-100">Finablr</span> <br />
            Service Provider.
          </div>
          <div className="text-gray-600 text-lg md:text-xl mt-4">
            Introducing Finablr Service Provider – the premier hub tailored exclusively for service
            providers seeking to enhance the efficiency and reliability of financial transactions
            within the Finablr network.
          </div>
          <div className="mt-4">
            <Button onClick={handleStart} variant="dark" size="lg">
              Get Started
            </Button>
          </div>
        </div>

        <div className="">
          <img src={MotivationRafiki} alt="Motivation Rafiki" className="" />
        </div>
      </div>
    </WebLayout>
  );
};
