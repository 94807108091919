import React, { useState } from 'react';
import { Spinner, Link } from 'components/Elements';
import { formatDate } from 'utils/format';
import { DeleteNotification } from './DeleteNotification';
import { useNotifications } from '../api/getNotifications';
import { useMarkNotificationAsRead } from '../api/markAsRead';
import { useNavigate } from 'react-router-dom';

export const NotificationsList = () => {
  const [displayedNotifications, setDisplayedNotifications] = useState(5);
  const notificationsQuery = useNotifications();

  const markAsReadMutation = useMarkNotificationAsRead();
  const navigate = useNavigate();

  const handleViewMore = () => {
    setDisplayedNotifications((prev) => prev + 5);
  };

  if (notificationsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!notificationsQuery.data || notificationsQuery.data.length === 0) {
    return <div className="text-gray-600">No notifications found.</div>;
  }

  return (
    <div className="flex flex-col space-y-3">
      {notificationsQuery.data.slice(0, displayedNotifications).map((notification) => (
        <button
          onClick={() => {
            if (!notification.read) {
              markAsReadMutation.mutate({ id: notification._id });
            }
            navigate(`/app/notifications/${notification._id}`);
          }}
          key={notification.id}
          className={`${
            notification.read ? `` : `border border-primary-100`
          } bg-white rounded-2xl flex flex-row justify-between p-4 shadow-md`}
        >
          <div className="flex flex-col text-start items-start">
            <h3
              className={`${
                notification.read ? `text-gray-500` : `text-primary-100`
              } text-sm md:text-md font-semibold`}
            >
              {notification.message.substring(0, 100).concat('...')}
            </h3>
            <p className="text-sm text-gray-500">{formatDate(notification.createdAt)}</p>
          </div>
          <div className="flex justify-end mt-2">
            <DeleteNotification id={notification.id} />
          </div>
        </button>
      ))}
      {displayedNotifications < notificationsQuery.data.length && (
        <button onClick={handleViewMore} className="text-primary-500 font-semibold mt-4">
          View More
        </button>
      )}
    </div>
  );
};
