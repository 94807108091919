import { Button, Link } from 'components/Elements';
import { Head } from 'components/Head';
import PropTypes from 'prop-types';
import logo from 'assets/logo.svg';

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-white flex flex-col sm:flex-row justify-center">
        <div className="flex flex-col justify-center py-4 sm:px-6 lg:px-8">
          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md flex flex-row justify-center">
            <div className="">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center">
                  <Link className="flex items-center text-white" to="/">
                    <img className="h-10 w-auto" src={logo} alt="finablr" />
                  </Link>
                </div>

                <h2 className="mt-3 text-center text-xl font-extrabold text-gray-900 dark:text-white">
                  {title}
                </h2>
              </div>
              <div className="my-3 mx-8">{children}</div>
              <div className="mt-4 mx-8 text-center text-sm">
                © 2024, Enabling Finance Limited or its affiliates. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
