import React, { useState } from 'react';
import { useUploadDirectorGuarantorshipContracts } from '../api/uploadDirectorGuarantorshipContracts';
import { Button, FullScreenSpinner, Spinner } from 'components/Elements';
import { DocumentUploadField, Form } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { AssignedTo } from './AssignedTo';
import { UPLOADS_URL } from 'config';
import { Check } from 'react-feather';
import { useDueDeligenceAsset } from '../api/getDueDeligenceAsset';

export const UploadDirectorGuarantorshipContracts = ({ id }) => {
  const [director_guarantorship_contracts, setDirectorGuarantorshipContracts] = useState(null);

  const uploadDirectorGuarantorshipContracts = useUploadDirectorGuarantorshipContracts();

  const assetQuery = useDueDeligenceAsset({ id });

  const navigate = useNavigate();

  if (assetQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (uploadDirectorGuarantorshipContracts.isLoading) return <FullScreenSpinner />;

  return (
    <div>
      {/* Director Guarantorship Contracts */}
      {assetQuery.data?.director_guarantorship_contracts?.document_required &&
        !assetQuery.data?.director_guarantorship_contracts?.location && (
          <>
            <Form
              id="upload-boundary-opening-survey-report"
              onSubmit={async () => {
                const bodyFormData = new FormData();
                bodyFormData.append(
                  'director_guarantorship_contracts',
                  director_guarantorship_contracts
                );
                await uploadDirectorGuarantorshipContracts.mutateAsync({
                  data: bodyFormData,
                  id: assetQuery.data._id,
                });
                navigate(`/app/due-deligences/${id}/process/success`);
              }}
            >
              {({ register, formState }) => (
                <>
                  <div className="bg-white rounded-2xl shadow-md p-12 mb-4">
                    <div className="text-lg mb-2">Director Guarantorship Contracts</div>
                    <div className="text-sm mb-3">
                      Lorem ipsum dolor sit amet consectetur. Diam sit rutrum enim dui lectus
                      pharetra consectetur donec gravida. Faucibus quisque dignissim faucibus
                      commodo odio tellus. Proin euismod non eget.
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="">
                        <DocumentUploadField
                          label="Upload Report"
                          error={formState.errors['director_guarantorship_contracts']}
                          onChange={(e) => {
                            setDirectorGuarantorshipContracts(e.target.files[0]);
                          }}
                        />
                      </div>
                      <div className="">
                        <div className="">Assigned to</div>
                        <AssignedTo
                          id={assetQuery.data?.director_guarantorship_contracts?.assigned_to}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button
                      isLoading={uploadDirectorGuarantorshipContracts.isLoading}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </>
        )}

      {assetQuery.data?.director_guarantorship_contracts?.location && (
        <div className="bg-white rounded-2xl shadow-md p-8 grid grid-cols-1 md:grid-cols-3 mb-4">
          <div className="">
            <div className="">Director Guarantorship Contracts</div>
            <a
              href={`${UPLOADS_URL}/documents/due_deligence/${assetQuery.data?.director_guarantorship_contracts?.location}`}
              target="_blank"
              rel="noreferrer"
              className="text-primary-100"
            >
              {assetQuery.data?.director_guarantorship_contracts?.location}
            </a>
          </div>
          <div className="">
            <AssignedTo id={assetQuery.data?.director_guarantorship_contracts?.assigned_to} />
          </div>
          <div className="flex justify-end">
            <div className="rounded-full bg-green-400 p-3 h-12 w-12 flex items-center justify-center">
              <Check size={20} className="text-white" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
