import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const sendMessage = ({ data }) => {
  return axios.post(`/messages/send`, data);
};

export const useSendMessage = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (newMessage) => {
      await queryClient.cancelQueries('thread');

      const previousMessages = queryClient.getQueryData('thread');

      queryClient.setQueryData('thread', [...(previousMessages || []), newMessage.data]);

      return { previousMessages };
    },
    onError: (_, __, context) => {
      if (context?.previousMessages) {
        queryClient.setQueryData('thread', context.previousMessages);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('thread');
      queryClient.invalidateQueries('threads');
    },
    ...config,
    mutationFn: sendMessage,
  });
};
