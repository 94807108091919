import { Button } from 'components/Elements';
import { Form, InputField, PhoneInputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';

// const schema = z.object({
//   phoneNumber: z.string().min(1, 'Required'),
//   password: z.string().min(1, 'Required'),
// });

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  const navigate = useNavigate();

  return (
    <div>
      {!showPasswordInput ? (
        <div className="flex flex-col space-y-5 mt-4">
          <PhoneInputField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          <div>
            <Button
              onClick={() => {
                setShowPasswordInput(true);
              }}
              className="w-full"
            >
              Next
            </Button>
          </div>
        </div>
      ) : (
        <Form
          onSubmit={async (values) => {
            values.phoneNumber = `+256` + phoneNumber;
            await login(values);
            onSuccess();
          }}
          // schema={schema}
        >
          {({ register, formState }) => (
            <>
              <div className="mt-4">
                Sign In
                {phoneNumber && (
                  <div className="text-sm text-gray-900">Phone Number: +256 {phoneNumber}</div>
                )}
              </div>
              <InputField
                type="password"
                label="Password"
                error={formState.errors['password']}
                registration={register('password')}
              />
              <div>
                <Button isLoading={isLoggingIn} type="submit" className="w-full">
                  Sign in
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      <button
        className="w-full text-sm text-primary-100"
        onClick={() => navigate('/auth/forgot-password')}
      >
        Forgot Password?
      </button>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
