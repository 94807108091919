import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getMyChats = () => {
  return axios.get('/messages/my-chats');
};

export const useMyChats = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['threads'],
    queryFn: () => getMyChats(),
  });
};
