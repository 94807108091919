import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { ThreadsList } from '../components/ThreadsList';
import { SendMessage } from '../components/SendMessage';

export const Threads = () => {
  const routes = [
    {
      name: 'Chats',
      path: '/app/chats',
    },
  ];
  const navigate = useNavigate();
  return (
    <ContentLayout title="Chats" end={<SendMessage />}>
      <Breadcrumbs routes={routes} />

      <div className="mt-8">
        <ThreadsList />
      </div>
    </ContentLayout>
  );
};
