import { Button, Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import React from 'react';
import { Form, RegularTextAreaField } from 'components/Form';
import { useSendMessage } from '../api/sendMessage';

export function SendMessage() {
  const [show, setShow] = React.useState(false);

  const sendMessageMutation = useSendMessage();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <Button size="sm" onClick={handleOpen}>Send Message</Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <Form
            id="lend-now"
            onSubmit={async (values) => {
              await sendMessageMutation.mutateAsync({
                data: values,
              });
            }}
          >
            {({ register, formState }) => (
              <>
                <DialogTitle className="text-xl text-center font-bold mb-4">
                  Send message to admin
                </DialogTitle>
                <DialogDescription>
                  <RegularTextAreaField
                    type="text"
                    label="Message"
                    error={formState.errors['text']}
                    registration={register('text')}
                  />
                </DialogDescription>
                <div className="flex flex-row items-center space-x-2">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handleClose}
                    className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl text-white bg-primary-100"
                  >
                    Send Message
                  </button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
