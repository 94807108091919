import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getUnreadMessageCount = () => {
  return axios.get('/messages/unread-message-count');
};

export const useUnreadMessageCount = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['messageCount'],
    queryFn: () => getUnreadMessageCount(),
  });
};
