import { Table, Spinner, Link, Button } from 'components/Elements';
import { formatDate } from 'utils/format';

import { UPLOADS_URL } from 'config';
import { useMyChats } from '../api/getMyChats';
import { formatRelativeTime } from 'utils/formatRelativeTime';
import { useNavigate } from 'react-router-dom';
import { useMarkAsRead } from '../api/markAsRead';

export const ThreadsList = () => {
  const threadsQuery = useMyChats();

  if (threadsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  console.log(threadsQuery.data);

  if (!threadsQuery.data) return null;

  return (
    <>
      <div className="grid grid-cols-1 gap-4">
        {threadsQuery.data?.chats?.map((thread) => (
          <Thread key={thread._id} thread={thread} />
        ))}
      </div>
    </>
  );
};

const Thread = ({ thread }) => {
  const navigate = useNavigate();

  const markAsReadMutation = useMarkAsRead({ threadId: thread._id });

  return (
    <button
      onClick={() => {
        markAsReadMutation.mutateAsync({ data: { threadId: thread._id } });
        navigate(`/app/chats/${thread._id}`);
      }}
      className="flex flex-row items-start justify-between bg-gray-100 shadow-sm rounded-lg p-4"
    >
      <div className="flex flex-row items-start space-x-2">
        <img
          src={UPLOADS_URL + '/images/users/' + thread.lastMessage?.from?.basicInformation?.avatar}
          alt="avatar"
          className="h-12 w-12 rounded-full"
        />
        <div className="flex flex-col items-start">
          <div className="text-gray-800 font-semibold text-xs md:text-md">
            {thread.lastMessage?.from?.authentication?.username}
          </div>
          <div className="text-gray-500 text-start text-xs md:text-sm">
            {thread.lastMessage?.text}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-end items-end">
        <div className="text-gray-800 text-xs">
          {formatRelativeTime(thread?.lastMessage?.createdAt)}
        </div>
        {thread?.lastUnreadMessages !== 0 && (
          <div className="bg-secondary-100 text-white rounded-full p-1 text-xs w-8 flex flex-row items-center justify-center">
            {thread?.lastUnreadMessages}
          </div>
        )}
      </div>
    </button>
  );
};
