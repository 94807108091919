import { Navigate, Route, Routes } from 'react-router-dom';

import { DueDeligenceCheckList } from './CheckList';

export const DueDeligenceRoutes = () => {
  return (
    <Routes>
      <Route path="checklist" element={<DueDeligenceCheckList />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
