import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, FullScreenSpinner, Spinner } from 'components/Elements';
import { DocumentUploadField, Form } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { AssignedTo } from './AssignedTo';
import { UPLOADS_URL } from 'config';
import { Check } from 'react-feather';
import { useDueDeligenceAsset } from '../api/getDueDeligenceAsset';
import { useUploadTitleSearchLetter } from '../api/uploadTitleSearchLetter';

export const UploadTitleSearchLetter = () => {
  const [title_search_letter, setTitleSearchLetter] = useState(null);

  const uploadTitleSearchLetterMutation = useUploadTitleSearchLetter();

  const { id } = useParams();

  const assetQuery = useDueDeligenceAsset({ id });

  const navigate = useNavigate();

  if (assetQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (uploadTitleSearchLetterMutation.isLoading) return <FullScreenSpinner />;

  return (
    <div>
      {assetQuery.data?.boundary_opening_survey_report?.location &&
        assetQuery.data?.title_search_letter?.document_required &&
        !assetQuery.data?.title_search_letter?.location && (
          <>
            <Form
              id="upload-certified-company-documents"
              onSubmit={async () => {
                const bodyFormData = new FormData();
                bodyFormData.append('title_search_letter', title_search_letter);
                await uploadTitleSearchLetterMutation.mutateAsync({
                  data: bodyFormData,
                  id: assetQuery.data._id,
                });
                navigate(`/app/due-deligences/${id}/process/success`);
              }}
            >
              {({ register, formState }) => (
                <>
                  <div className="bg-white rounded-2xl shadow-md p-12 mb-4">
                    <div className="text-lg mb-2">Title Search Letter</div>
                    <div className="text-sm mb-3">
                      Lorem ipsum dolor sit amet consectetur. Diam sit rutrum enim dui lectus
                      pharetra consectetur donec gravida. Faucibus quisque dignissim faucibus
                      commodo odio tellus. Proin euismod non eget.
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="">
                        <DocumentUploadField
                          label="Upload Documents"
                          error={formState.errors['title_search_letter']}
                          onChange={(e) => {
                            setTitleSearchLetter(e.target.files[0]);
                          }}
                        />
                      </div>
                      <div className="">
                        <div className="">Assigned to</div>
                        <AssignedTo id={assetQuery.data?.title_search_letter?.assigned_to} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button isLoading={uploadTitleSearchLetterMutation.isLoading} type="submit">
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </>
        )}

      {assetQuery.data?.title_search_letter?.location && (
        <div className="bg-white rounded-2xl shadow-md p-8 grid grid-cols-1 md:grid-cols-3 mb-4">
          <div className="">
            <div className="">Title Search Letter</div>
            <a
              href={`${UPLOADS_URL}/documents/due_deligence/${assetQuery.data?.title_search_letter?.location}`}
              target="_blank"
              rel="noreferrer"
              className="text-primary-100"
            >
              {assetQuery.data?.title_search_letter?.location}
            </a>
          </div>
          <div className="">
            <AssignedTo id={assetQuery.data?.title_search_letter?.assigned_to} />
          </div>
          <div className="flex justify-end">
            <div className="rounded-full bg-green-400 p-3 h-12 w-12 flex items-center justify-center">
              <Check size={20} className="text-white" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
