import React from 'react';
import { Spinner } from 'components/Elements';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import { useMyChecklist } from '../../notifications/api/getMyChecklist';
import { formatDate } from 'utils/format';
import { useAuth } from 'lib/auth';

export const DueDeligenceCheckList = () => {
  const checklistQuery = useMyChecklist();

  const { user } = useAuth();

  if (checklistQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  const renderTable = () => {
    return (
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2">Document</th>
            <th className="border border-gray-300 px-4 py-2">Status</th>
            <th className="border border-gray-300 px-4 py-2">Scheduled Dates</th>
            <th className="border border-gray-300 px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          {checklistQuery.data.map((item) => (
            <tr>
              <td className="border border-gray-300 px-4 py-2">{item[item]}</td>
              <td className="border border-gray-300 px-4 py-2">
                {item.boundary_opening_survey_report?.status}
              </td>
              <td className="border border-gray-300 px-4 py-2 grid gap-2">
                {item.boundary_opening_survey_report?.dates?.map((d) => (
                  <button disabled className="bg-gray-100 rounded-lg p-1 px-2 text-xs">
                    {formatDate(d.date)}
                  </button>
                ))}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button className="bg-primary-100 text-white p-2 rounded-lg">Upload</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  if (!checklistQuery.data || checklistQuery.data.length === 0) {
    return (
      <>
        <Head title={`Due Diligence Checklist`} />
        <ContentLayout title={`Due Diligence Checklist`}>
          <div className="mt-6">
            <p>No items found in the checklist.</p>
          </div>
        </ContentLayout>
      </>
    );
  }

  return (
    <>
      <Head title={`Due Diligence Checklist`} />
      <ContentLayout title={`Due Diligence Checklist`}>
        <div className="mt-6">
          <div className="bg-white rounded-2xl shadow-md p-8 mb-4">
            <div className="text-lg">Due-Deligence List and appointment reminders</div>
          </div>
          <div className="bg-white rounded-2xl shadow-md p-8 mb-4">
            <div className="mb-4">
              Please submit the following documents by the scheduled dates set.
            </div>
            {renderTable()}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
